import Video from 'twilio-video';
import Bowser from 'bowser';

/**
 * Gets the user's device information (browser, operating system, platform, engine)
 *
 * @returns {Object}
 */
function currentDevice() {
  return Bowser.getParser(window.navigator.userAgent);
}

/**
 * Determines compatibility of the current browser with The Room.
 *
 * @returns {Boolean} True if we support this browser for The Room.
 */
export function isBrowserSupported() {
  const isValidBrowser = currentDevice().satisfies({
    chrome: '>57',
    firefox: '>52',
  });

  return Video.isSupported && isValidBrowser;
}
