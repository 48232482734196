// We don't process uploaded files (resumes or photos) so limit the filesize to prevent an extremely large file
// from being updated. Additionally, the max upload size is bounded by the nginx `client_max_body_size` config.
const MAX_SIZE_IN_BYTES = 5 * 1024 * 1024; // 5MiB

$(document).ready(() => {
  $('input:file').closest('form').submit(validateAllFileUploads);
});

function validateAllFileUploads(event) {
  const form = event.target;
  const fileUploads = $(form).find('input:file');

  let success = true;
  for (let i = 0; i < fileUploads.length; i++) {
    if (!validateFileUploadSize(fileUploads[i])) {
      success = false;
    }
  }

  return success;
}

function validateFileUploadSize(element) {
  // Clear any previous error message
  if (element.nextElementSibling && element.nextElementSibling.className === 'flash error') {
    element.nextElementSibling.remove();
  }

  if (element.files.length > 0 && element.files[0].size > MAX_SIZE_IN_BYTES) {
    element.value = '';

    const errorDiv = document.createElement('div');
    errorDiv.className = 'flash error';
    errorDiv.innerText = 'File upload size limit of ' + (MAX_SIZE_IN_BYTES / (1024 * 1024)).toFixed(2) + 'MiB';

    $(errorDiv).insertAfter($(element));

    return false;
  }

  return true;
}
