$(document).ready(() => {
  $('.expandable_container > .header').on('click', toggleContainer);
});

export function openContainer(element) {
  changeContainerState(element, 'slideDown');
}

export function closeContainer(element) {
  changeContainerState(element, 'slideUp');
}

export function toggleContainer(event) {
  const element = event.currentTarget.parentNode;
  changeContainerState(element, 'slideToggle');
}

function changeContainerState(_element, func) {
  const element = $(_element);

  if (element.hasClass('open')) {
    element.addClass('closing');
  } else if (element.hasClass('closed')) {
    element.addClass('opening');
  }

  const body = element.children('.body');

  body[func]({
    'duration': 500,
    'complete': function () {
      element.toggleClass('open');
      element.toggleClass('closed');

      element.removeClass('opening');
      element.removeClass('closing');
    },
  });
}

function getContainerFromChild(element) {
  return $(element).parents('.expandable_container');
}

export function isContainerClosedFromChild(element) {
  const container = getContainerFromChild(element);
  if (container.length === 0) throw new Error('not a container');

  return container.hasClass('closed') || container.hasClass('closing');
}
