import { initNewSortingField } from 'application/forms';

$(document).ready(() => {
  addDynamicAddLinkListeners();
});

function addDynamicAddLinkListeners() {
  const links = $('[data-dynamic-add-link=true]');
  links.unbind('click');
  links.on('click', onDynamicAddLinkClick);
}

function onDynamicAddLinkClick(event) {
  const time = new Date().getTime();
  const regex = new RegExp($(this).data('id'), 'g');

  $(this).parent().before($(this).data('fields').replace(regex, time));

  // Add click listeners to any dynamic add links inside the fields of the link that just clicked
  addDynamicAddLinkListeners();

  // If the form is equipped with sortable fields, initialize the position for the new field
  if (typeof initNewSortingField == 'function') {
    initNewSortingField();
  }

  event.preventDefault();
}
