export function registerAjaxCallbacks(element, sendCallback, successCallback, errorCallback) {
  if (sendCallback) registerAjaxCallback(element, 'send', sendCallback);
  if (successCallback) registerAjaxCallback(element, 'success', successCallback);
  if (errorCallback) registerAjaxCallback(element, 'error', errorCallback);
}

function registerAjaxCallback(element, type, callback) {
  element.bind(`ajax:${type}`, (event) => {
    // Position of XHR in detail depends on event type
    // https://guides.rubyonrails.org/working_with_javascript_in_rails.html#rails-ujs-event-handlers
    const xhr = type === 'send' ? event.detail[0] : event.detail[2];
    const response = ['success', 'error'].includes(type) ? event.detail[0] : null;
    callback(event, xhr, response);
  });
}

export function prependErrors(element, response) {
  // Remove existing errors and prepend the new ones to the form
  const form = $(element + ' form');
  form.find('#error_explanation').remove();
  form.prepend(response.responseText);
}
