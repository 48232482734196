// eslint-disable-next-line no-console
console.log(`\
                              /\\       ___________________
   _  __               _     /  \\     < Welcome to Karat! >
  | |/ /__ _ _ __ __ _| |_  / /\\ \\     -------------------
  | ' // _\` | '__/ _\` | __|                              \\   ^__^
  | . \\ (_| | | | (_| | |_                                \\  (oo)\\_______
  |_|\\_\\__,_|_|  \\__,_|\\__|                                  (__)\\       )\\/\\
                                                                 ||----w |
  https://karat.com/careers                                      ||     ||
`);

/* eslint-disable import/first */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'popper.js';

import Rails from '@rails/ujs';
Rails.start();

import 'feather-icons/dist/feather-sprite.svg';

// OneTrust
import 'application/oneTrust';

// Chameleon
import 'application/chameleon';

// Angular
import 'angular';
import 'angular-animate';
import 'angular-sanitize';

// Autolinker
import 'autolinker';

// Bowser
import 'bowser';

// JQuery
import 'jquery-ui';
import 'jquery-ui/ui/widgets/resizable';

// Bootstrap
import 'bootstrap';

// Lodash
import 'lodash';

// MomentJS
import 'moment-timezone';

// Intl-tel-input
import 'intl-tel-input/build/js/utils';

// React
import 'webpacker-react';

// Redux
import 'redux';

// URI.js
import 'urijs';

// VideoJS
import 'video.js';
import 'videojs-seek-buttons';

import 'application/ajax_form';
import 'application/copyable';
import 'application/countdown';
import 'application/drawer';
import 'application/dynamic_add_model_fields';
import 'application/expandable_container';
import 'application/file_size_limit';
import 'application/forms';
import 'application/phone_input';
import 'application/the_room_check';
import 'application/tooltips';
import 'application/resize_text_area';
import 'application/enable_popovers';
