export default function enablePopovers() {
  $(() => {
    $('[data-toggle="popover"]').popover();
  });

  $('[data-toggle="popover"][data-timeout]').on('shown.bs.popover', function () {
    const popoverElement = $(this);
    setTimeout(() => {
      popoverElement.popover('hide');
    }, $(this).data('timeout'));
  });
}

$(() => {
  enablePopovers();
});
