// Deprecated in favor of Vera PhoneInput, remove this file after all use cases
// have been replaced with that version

import 'intl-tel-input';

// Used for phone number collection component creation.
// phone_input element used for initializing plugin
// user_phone element used for saving full number as user param
// NOTE: Handles one phone number input per page

// Create intl-tel-input dropdown menu for singular phone input on the page
export function initializeTelephoneInput(hiddenField = '#user_phone') {
  const input = $(hiddenField);

  // Initialize plugin with desired settings
  $('.phone :input:first').intlTelInput({
    preferredCountries: ['us', 'ca', 'gb', 'ar', 'cn'],
    initialCountry: 'us',
    separateDialCode: true,
    autoFormat: true,
    autoPlaceholder: false,
  });

  registerPhoneNumberValidation(input);
  registerPhoneNumberSave(input);
}

// Displays warning to user if phone number does not appear valid
function registerPhoneNumberValidation(input) {
  const telInput = $('.phone :input:first');
  const phoneWarningIcon = $('#phone_warning_icon');
  const phoneWarningMessage = $('#phone_number_warning');

  // Hide warning icon and message
  const reset = function () {
    phoneWarningIcon.removeClass('show_warning_message');
    phoneWarningMessage.removeClass('show_warning_message');
  };

  // On blur: validate
  telInput.blur(() => {
    reset();
    syncPhoneNumberValues(input);
    if ($.trim(telInput.val())) {
      if (!telInput.intlTelInput('isValidNumber')) {
        phoneWarningIcon.addClass('show_warning_message');
        phoneWarningMessage.addClass('show_warning_message');
      }
    }
  });

  // Listen to the telephone input for changes
  telInput.change(() => {
    // Format phone number
    telInput.intlTelInput('setNumber', telInput.val());
    // Keep values in sync
    syncPhoneNumberValues(input);
  });

  // Remove warning icon and message when user is typing
  telInput.on('keyup change', reset);

  // Validate phone number on page load
  telInput.blur();
}

// Saves full phone number to user phone param
function registerPhoneNumberSave(input) {
  $('form').submit(() => {
    syncPhoneNumberValues(input);
  });
}

function syncPhoneNumberValues(input) {
  const telInput = $('.phone :input:first');

  // Save the full country code and national number to user data
  // No number given (only country code selected), treat as empty value
  if (telInput.val() !== '') {
    input.val(telInput.intlTelInput('getNumber').replace(/\D/g, ''));
  } else {
    input.val('');
  }
}
