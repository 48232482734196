$(document).ready(() => {
  $('.copy').click(({ currentTarget }) => copyToClipboard(currentTarget.dataset.target, currentTarget.dataset.select));
});

function copyToClipboard(id, shouldSelect = false) {
  // Deselect any existing content
  window.getSelection().removeAllRanges();

  // Select the content of the element
  const valueToBeCopied = document.getElementById(id);
  valueToBeCopied.select();
  valueToBeCopied.setSelectionRange(0, valueToBeCopied.selectionEnd);

  document.execCommand('copy');

  if (shouldSelect) {
    return;
  }

  // Deselect the content
  window.getSelection().removeAllRanges();
}
