/**
 * Using the Bootstrap tooltip, there isn't an easy way to assign a custom class
 * to the content div for custom styling purposes, so we directly do so.
 */

$(document).ready(() => {
  $('.tooltip').tooltip({
    template:
      '<div class="tooltip" role="tooltip">' +
      '<div class="arrow"></div>' +
      '<div class="tooltip-inner tooltip_content"></div></div>',
    placement: 'top',
  });
});
