import 'jquery-ui/ui/widgets/sortable';

$(() => {
  $('.sortable').sortable({
    stop: doneSorting,
    items: '> .sorted',
  });

  $('.sortable').disableSelection();

  // Bootstrap form validation
  $('.needs-validation').each((index, formElement) => {
    $(formElement).on('submit', (event) => {
      if (formElement.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }

      $(formElement).addClass('was-validated');
    });
  });
});

function doneSorting() {
  const elements = $('.sortable > div');

  for (let i = 0; i < elements.length; i++) {
    const positionField = $(elements[i]).find('[data-position=true]');
    positionField.val(i);
  }
}

export function initNewSortingField() {
  doneSorting();
}
