import Cookies from 'js-cookie';

const Selector = {
  DRAWER: '.drawer',
  SCRIM: '.drawer-scrim',
  TOGGLE: '[data-toggle="drawer"]',
};

const ClassName = {
  OPEN: 'open',
  MODAL: 'drawer-modal',
};

class Drawer {
  constructor(element) {
    this.$element = $(element);
    this.$toggle = $(Selector.TOGGLE).filter((_, toggle) => this.$element.is($(toggle).data('target')));
    this.$scrim = this.$element.siblings(Selector.SCRIM);
    this.timer = null;
    this.createEventListeners();
  }

  open(modal = false) {
    const className = [ClassName.OPEN];

    if (modal) {
      className.push(ClassName.MODAL);
    }

    this.$element.addClass(className);
    this.$toggle.attr('aria-expanded', 'true');
    this.setStateCookie();
  }

  close() {
    this.$element.find('.collapse').collapse('hide');
    this.$element.find('[data-toggle="dropdown"]').dropdown('hide');
    this.$element.removeClass([ClassName.OPEN, ClassName.MODAL]);
    this.$toggle.attr('aria-expanded', 'false');
    this.setStateCookie();
  }

  isOpen() {
    return this.$element.hasClass(ClassName.OPEN);
  }

  isModal() {
    return this.$element.hasClass(ClassName.MODAL);
  }

  // Persists the drawer state across page loads in a shady attempt to mimic
  // SPA-like behavior. By using a cookie new pages can be rendered with the
  // correct classes already applied and prevent FOUC.
  setStateCookie() {
    const state = {
      open: this.isOpen(),
      modal: this.isModal(),
    };

    Cookies.set('drawer_state', state, { expires: 30, secure: window.location.protocol === 'https' });
  }

  handleMouseOver() {
    if (this.isOpen() || this.timer !== null || this.$toggle.get().some((el) => el.matches(':hover'))) {
      return;
    }

    this.timer = setTimeout(() => {
      if (!this.isOpen()) {
        this.open(true);
      }
    }, 200);
  }

  handleMouseLeave() {
    clearTimeout(this.timer);
    this.timer = null;

    if (this.isModal()) {
      this.close();
    }
  }

  handleScrimClick() {
    this.close();
  }

  handleScrimMouseMove() {
    this.close();
  }

  handleToggleClick() {
    if (this.isOpen()) {
      this.close();
    } else {
      this.open();
    }
  }

  // Freezes the drawer before leaving the page so it's still in sync with the
  // state cookie when the new page loads.
  handleBeforeUnload() {
    this.destroyEventListeners();
  }

  createEventListeners() {
    this.$element.on('mouseover', (event) => {
      this.handleMouseOver(event);
    });

    this.$element.on('mouseleave', (event) => {
      this.handleMouseLeave(event);
    });

    this.$toggle.on('click', (event) => {
      this.handleToggleClick(event);
    });

    this.$scrim.on('mousemove', (event) => {
      this.handleScrimMouseMove(event);
    });

    this.$scrim.on('click', (event) => {
      this.handleScrimClick(event);
    });

    $(window).on('beforeunload', (event) => {
      this.handleBeforeUnload(event);
    });
  }

  destroyEventListeners() {
    this.$element.off();
    this.$toggle.off();
    this.$scrim.off();
  }
}

$(() => new Drawer(document.querySelector(Selector.DRAWER)));
