// Taken from http://bdadam.com/blog/automatically-adapting-the-height-textarea.html
const resizableHeightClass = 'custom-auto-resize';

$(document).ready(() => {
  resizeTextAreas();
  $('.nav.nav-tabs .nav-item a.nav-link').each(function () {
    $(this).on('shown.bs.tab', () => {
      resizeTextAreas();
    });
  });
});

function adjustHeight(textareaElement) {
  // Compute the height difference which is caused by border and outline
  const outerHeight = parseInt(window.getComputedStyle(textareaElement).height, 10);
  const diff = outerHeight - textareaElement.clientHeight;

  // Set the height to 0 in case of it has to be shrinked
  textareaElement.style.height = 0;

  // Set the correct height
  textareaElement.style.height = textareaElement.scrollHeight + diff + 'px';
}

function resizeTextAreas() {
  // Get all elements with our resize class attached
  const textAreas = [].slice.call(document.querySelectorAll('textarea.' + resizableHeightClass));

  // Iterate through all the textareas on the page
  textAreas.forEach((el) => {
    // We need box-sizing: border-box, if the textarea has padding
    el.style.boxSizing = el.style.mozBoxSizing = 'border-box';

    // We don't need any scrollbars, do we? :)
    el.style.overflowY = 'hidden';

    el.addEventListener('input', () => {
      adjustHeight(el);
    });

    // We have to readjust when window size changes (e.g. orientation change)
    window.addEventListener('resize', () => {
      adjustHeight(el);
    });

    // We adjust height to the initial content
    adjustHeight(el);
  });
}
