const REFRESH_INTERVAL = 60 * 1000; // 60 seconds

/**
 * Creates a countdown on a control (enabling/disabling and displaying some numbers).
 *
 * @param {String} target A CSS selector targeting the control.
 */
export function initializeCountdown(target, callback) {
  const control = $(target);

  if (control.data('enabled') === true) {
    const timeout = control.data('timeout');

    // Wait until the specified time to enable the target
    window.setTimeout(() => {
      control.prop('disabled', !control.prop('disabled'));
      control.attr('value', control.data('enable-with'));
      // Clear the time until text at the same time as enabling the control
      fillTimeRemaining(0);

      if (callback) {
        callback();
      }
    }, timeout);

    // Update the time until text periodically
    let countdown = timeout;
    fillTimeRemaining(countdown);

    if (timeout > 0) {
      window.setInterval(() => {
        fillTimeRemaining(countdown);
        countdown -= REFRESH_INTERVAL;
      }, REFRESH_INTERVAL);
    }
  }
}

/**
 * Fills .countdown__value with a readable description of the time remaining.
 *
 * @param {Number} countdown How much time is left in milliseconds.
 */
function fillTimeRemaining(countdown) {
  const textTarget = $('.countdown__value');

  const minutes = Math.round(countdown / 1000 / 60);
  const hours = Math.round(minutes / 60);

  if (hours > 1) {
    textTarget.text(pluralize(hours, 'hour'));
  } else if (minutes >= 1) {
    textTarget.text(pluralize(minutes, 'minute'));
  } else if (countdown > 0) {
    textTarget.text('mere seconds');
  } else {
    $('.countdown__text').text('');
  }
}

/**
 * Ignorantly pluralizes a word with an `s` if count is more than one.
 *
 * @param {Number} count How many of the thing!
 * @param {String} word The word to pluralize.
 */
function pluralize(count, word) {
  let pluralizedWord = word;
  if (count > 1) {
    pluralizedWord = pluralizedWord.concat('s');
  }

  return [count, pluralizedWord].join(' ');
}
